<template>
  <base-missing-data-page class="employees-missing-data" :url-img="$options.imageDataMissing">
    <template #message>
      {{ $t('role.missing_message') }}
    </template>
  </base-missing-data-page>
</template>

<script>
import BaseMissingDataPage from '@/components/BaseMissingDataPage.vue';

import imageDataMissing from '@/assets/imageMissingData/missing-data-employees.svg';

export default {
  name: 'EmployeesMissingData',

  components: { BaseMissingDataPage },

  imageDataMissing,
};
</script>
