<template>
  <div class="roles">
    <entity-status-list :statuses="entityEmployeesStatuses">
      <template>
        <template v-if="!isArchive && !media.isMobile">
          <v-btn large class="mr-3" elevation="0" color="primary" :to="roleRoute">
            {{ $t('button.add_role') }}
            <v-icon right>mdi-plus</v-icon>
          </v-btn>
        </template>
      </template>
    </entity-status-list>

    <roles-missing-data v-if="isDataMissing" class="roles__missing-data" />

    <template v-else>
      <div v-if="media.isMobile" class="d-flex flex-column mb-1 mb-md-4">
        <div class="d-flex align-center mb-6">
          <mobile-search-menu
            :key="$route.name"
            v-model="queryModel"
            :placeholder="$t('label.roles_search')"
            single-line
            full-width
            hide-details
          />

          <icon-button class="ms-7" color="primary" elevation="0" :width="44" :to="roleRoute">
            <v-icon>mdi-plus</v-icon>
          </icon-button>
        </div>
      </div>

      <div class="roles__list">
        <list-loading v-if="isLoading" />
        <template v-else>
          <search-bar
            v-if="!media.isMobile"
            :key="$route.name"
            v-model="queryModel"
            class="mb-6"
            :placeholder="$t('label.roles_search')"
            single-line
            full-width
            hide-details
          />

          <roles-mobile-list v-if="media.isMobile" :items="roles" @open="openDetailed" />

          <base-table
            v-else
            v-model="selected"
            hide-default-footer
            :items="roles"
            :headers="rolesHeaders"
            :loading="tableLoading"
            :options.sync="options"
            :server-items-length="employeesLimit"
            @click:row="openDetailed"
          >
            <template v-slot:item.credentials="{ item }">
              {{ joinWithLimitTranslate(item.credentials, 2) }}
            </template>

            <template v-slot:item.actions="{ item }">
              <div class="actions">
                <icon-button exact class="mr-2" :width="36" @click.stop @click="openEditRole(item)">
                  <v-icon color="primary">mdi-pencil</v-icon>
                </icon-button>

                <icon-button
                  exact
                  :width="36"
                  class="btn-delete-role"
                  :class="{ disabled: item.disabled }"
                  @click.stop="!item.disabled && deleteRole(item.id)"
                >
                  <v-icon color="primary">mdi-delete</v-icon>
                </icon-button>
              </div>
            </template>
          </base-table>
        </template>
      </div>
      <base-pagination v-if="pageCount && !isLoading" v-model="pageModel" :length="pageCount" class="mt-10" />
    </template>
  </div>
</template>

<script>
// Http
import client from '@/http/client';

// Constants
import { EMPLOYEES, EMPLOYEES_ARCHIVE, ROLES, ROLES_DETAILED, ROLES_CREATE, ROLES_EDIT } from '@/constants/routes';
import * as entityStatuses from '@/constants/entityStatuses';
import { ROLES_LIST } from '@/constants/analyticsActions';

// Utils
import { throttle } from '@/utils/delay';
import { camelToSnake } from '@/utils/formatters';
import { flushPromises } from '@/utils/scheduler';
import joinWithLimit from '@/utils/joinWithLimit';
import { getCredentialsFromSections } from '@/utils/roles';

// Services
import usersService from '@/services/users';
import analyticsService from '@/services/analytics';

// Components
import MobileSearchMenu from '@/components/MobileSearchMenu.vue';
import ListLoading from '@/components/ListLoading.vue';
import RolesMobileList from '@/components/Roles/MobileList.vue';
import RolesMissingData from '@/components/Roles/MissingData.vue';
import BasePagination from '@/components/BasePagination.vue';
import BaseTable from '@/components/BaseTable.vue';
import SearchBar from '@/components/SearchBar.vue';
import EntityStatusList from '@/components/EntityStatusList.vue';
import IconButton from '@/components/IconButton.vue';

const credentialsConfig = {
  invoices: { label: 'navigation.invoice', withCredentials: true },
  contractors: { label: 'navigation.contractors', withCredentials: true },
  clients: { label: 'navigation.clients', withCredentials: true },
  contracts: { label: 'navigation.contracts', withCredentials: true },
  announcements: { label: 'navigation.announcements', withCredentials: true },
  employees: { label: 'navigation.employees', withCredentials: true },
  chat: { label: 'navigation.chat', withCredentials: false },
  projects: { label: 'navigation.projects', withCredentials: true },
  dashboard: { label: 'navigation.dashboard', withCredentials: false },
  issues: { label: 'navigation.task', withCredentials: true },
  services: { label: 'navigation.services', withCredentials: true },
};

export default {
  name: 'Employees',

  components: {
    MobileSearchMenu,
    ListLoading,
    RolesMobileList,
    RolesMissingData,
    BasePagination,
    BaseTable,
    SearchBar,
    EntityStatusList,
    IconButton,
  },

  inject: ['media'],

  props: {
    status: { type: String, required: true },
  },

  data() {
    return {
      query: '',
      page: +this.$route.query.page || 1,
      pageCount: 1,
      employeesLimit: 10,
      rolesCount: 0,
      roles: [],
      isLoading: false,
      tableLoading: false,
      isDataMissing: false,
      selected: [],
      globalSelect: false,
      dialogIsOpen: false,
      options: { sortBy: '', sortDesc: null },
    };
  },

  computed: {
    pageRolesCount() {
      return this.roles.length;
    },
    entityEmployeesStatuses() {
      return [
        { id: 0, text: this.$t('employees.employees'), name: EMPLOYEES },
        { id: 1, text: this.$t('employees.archive'), name: EMPLOYEES_ARCHIVE },
        { id: 1, text: this.$t('employees.roles'), name: ROLES },
      ];
    },

    roleRoute() {
      return { name: ROLES_CREATE };
    },

    orderBy() {
      if (!this.options.sortBy || this.options.sortDesc === null) return undefined;

      const header = this.rolesHeaders.find(employeeHeader => employeeHeader.value === this.options.sortBy);

      const value = camelToSnake(header?.sortValue || this.options.sortBy);

      if (this.options.sortDesc === null) return value;
      return this.options.sortDesc ? `-${value}` : value;
    },

    rolesHeaders() {
      return [
        {
          sortText: this.$t('sort_by.role'),
          text: this.$t('role.title'),
          value: 'name',
          width: '30%',
          sortable: true,
        },
        {
          text: this.$t('role.access_levels'),
          value: 'accessLevels',
          sortable: false,
        },
        {
          value: 'actions',
          sortable: false,
        },
      ];
    },

    activeHeader() {
      return this.rolesHeaders.find(
        header => header.value === this.options.sortBy || header?.sortValue === this.options.sortBy
      );
    },

    pageModel: {
      get() {
        return this.page;
      },

      set(value) {
        this.$router.push({
          name: this.$route.name,
          query: { page: value },
        });
      },
    },

    queryModel: {
      get() {
        return this.query;
      },

      set(value) {
        this.query = value;
      },
    },

    offset() {
      return (this.page - 1) * this.employeesLimit;
    },

    isArchive() {
      return this.status === entityStatuses.ARCHIVED;
    },

    isShowRoles() {
      return this.status === entityStatuses.SHOW_ROLES;
    },
  },

  watch: {
    status() {
      this.selected = [];
      this.globalSelect = false;
    },

    selected(newValue) {
      if (newValue.length < this.pageClientsCount) {
        this.globalSelect = false;
      }
    },

    page() {
      this.selected = [];
      this.globalSelect = false;
    },

    orderBy() {
      this.getRoles();
    },

    // eslint-disable-next-line
    '$route.query.page': function(newValue) {
      if (newValue) {
        if (this.pageModel === +newValue) {
          return;
        }
        this.page = +newValue || 1;
        this.getRoles();
      }
    },

    // eslint-disable-next-line
    '$route.name': function(newValue, oldValue) {
      if (newValue === oldValue) return;
      if (this.pageModel !== 1) {
        this.pageModel = 1;
        return;
      }
      if (this.isDataMissing) {
        this.isDataMissing = false;
      }
      this.getRoles();
    },

    queryModel(newValue, oldValue) {
      if (newValue === oldValue) return;
      this.$options.throttledSearch();
    },
  },

  mounted() {
    analyticsService.track(ROLES_LIST);
    this.isLoading = true;

    if (this.$route.query.page === undefined)
      this.$router.push({
        path: this.$route.path,
        query: { page: this.pageModel },
      });

    this.getRoles()
      .then(() => {
        if (this.roles.length === 0 && !this.query) {
          this.isDataMissing = true;
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  },

  beforeMount() {
    this.$options.throttledSearch = throttle(() => {
      this.getRoles();
    }, 500);
  },

  methods: {
    joinWithLimit,
    getCredentialsFromSections,
    joinWithLimitTranslate(array, limit = 3) {
      return this.joinWithLimit(
        array.map(item => this.$t(item)),
        limit
      );
    },

    async getRoles() {
      if (this.$options.cancelRequestNewEmployees) {
        this.$options.cancelRequestNewEmployees();
        await flushPromises();
      }

      this.selected = [];
      this.tableLoading = true;

      try {
        const cancelSource = client.getCancelToken();
        this.$options.cancelRequestNewEmployees = cancelSource.cancel;

        const data = await usersService.getRoles(
          {
            limit: this.employeesLimit,
            offset: this.offset,
            search: this.query,
            orderBy: this.orderBy,
          },
          {
            cancelToken: cancelSource.token,
          }
        );

        const { count, results } = data;

        this.pageCount = Math.ceil(count / this.employeesLimit);

        this.rolesCount = count;

        this.roles = results?.map(role => this.normalizeData(role));

        if (!results.length && this.page > 1) {
          this.page -= 1;
          this.getRoles();
        }
      } finally {
        this.tableLoading = false;
        this.$options.cancelRequestNewEmployees = null;
      }
    },

    openDetailed(item) {
      this.$router.push({ name: ROLES_DETAILED, params: { id: item.id, prevPage: this.$route.query.page } });
    },

    openEditRole(item) {
      this.$router.push({ name: ROLES_EDIT, params: { id: item.id, prevPage: this.$route.query.page } });
    },

    async deleteRole(roleId) {
      usersService.deleteRole(roleId).then(() => this.getRoles());
    },

    normalizeData(role) {
      const credentials = this.getCredentialsFromSections(role);
      const fitredCredentials = this.filterCredentials(credentials);
      const accessLevels = this.joinWithLimitTranslate(fitredCredentials, 2);

      return {
        id: role.id,
        name: role.name,
        disabled: role.roleInUse,
        accessLevels,
      };
    },

    filterCredentials(accessLevels) {
      const filtredCredentialsConfig = [];

      Object.entries(credentialsConfig).filter(([name, config]) => {
        let item;
        const value = accessLevels[name];

        if (value.length) {
          item = config.label;
          filtredCredentialsConfig.push(item);
        }

        return item;
      });

      return filtredCredentialsConfig;
    },
  },

  cancelRequestNewEmployees: null,
};
</script>

<style lang="scss">
.roles {
  height: 100%;

  &--archive {
    .text-start {
      color: rgba($--black-color-0, 0.5);
    }
  }

  &__missing-data {
    height: 100%;
  }

  &__list {
    min-height: 455px;
  }

  .btn-delete-role {
    &.disabled {
      opacity: 0.3;
    }
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
}
</style>
